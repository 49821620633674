import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ThemeUi from 'theme-ui'

export function Logotype() {
  return (
    <ThemeUi.Link
      as={ReactRouterDom.Link}
      to="/"
      onClick={() => window.scrollTo(0, 0)}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ThemeUi.Image
        src="/images/logo.svg"
        alt="logo"
        height="25"
        width="99"
        sx={{
          height: ['25px', '30px'],
          width: 'auto',
        }}
      />
    </ThemeUi.Link>
  )
}
