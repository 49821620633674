import * as Herz from '@rushplay/herz'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Session from './session'
import * as ThemeUi from 'theme-ui'

import {Logotype} from './logotype'

export function Topbar() {
  const translate = Herz.I18n.useTranslate()

  const authenticated = ReactRedux.useSelector((state) =>
    Session.isAuthenticated(state.session)
  )

  return (
    <ThemeUi.Box
      as="header"
      sx={{
        height: '56px',
        backgroundColor: 'nav-background',
        color: 'static-white',
        width: '100%',
        display: 'flex',
        position: 'sticky',
        top: '-1px',
        left: '0px',
        right: '0px',
        zIndex: 1,
        px: 1,
      }}
    >
      <ThemeUi.Box
        sx={{
          position: 'relative',
          mx: 'auto',
          maxWidth: 'container.lg',
          width: '100%',
        }}
      >
        <ThemeUi.Box
          sx={{
            backgroundColor: 'nav-background',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Logotype />
          {authenticated ? (
            <ReactRouterDom.Link to="/logout">
              <ThemeUi.Button variant="muted">
                {translate('topbar.logout')}
              </ThemeUi.Button>
            </ReactRouterDom.Link>
          ) : (
            <ThemeUi.Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              <ReactRouterDom.Link to="?login=me">
                <ThemeUi.Button variant="secondary">
                  {translate('topbar.login')}
                </ThemeUi.Button>
              </ReactRouterDom.Link>
              <ReactRouterDom.Link to="?register=now">
                <ThemeUi.Button>{translate('topbar.register')}</ThemeUi.Button>
              </ReactRouterDom.Link>
            </ThemeUi.Box>
          )}
        </ThemeUi.Box>
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

Herz.I18n.Loader.preload(
  ['topbar.logout', 'topbar.login', 'topbar.register'],
  Topbar
)

export default Topbar
